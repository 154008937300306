import React from "react";
import "./modal.scss";

export default class Modal extends React.Component {
  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <div className="modal">
        <div className="actions">
        <div className="model-close">
          <button
            className="delete-btn"
            onClick={this.props.onButtonClose}
          ></button>
          </div>
          <div className="model-title"><span>{this.props.title}</span></div>
          
        </div>
        <div className="content">{this.props.children}</div>
      </div>
    );
  }
}
